@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@layer base{
  body {
    @apply font-[Raleway]
  }
  li {
    @apply px-4;
    @apply cursor-pointer
  }
  .title-font {
    font-family: 'Libre Baskerville', serif;
  }
}